import * as React from 'react';
import { graphql, navigate } from 'gatsby';
import { siteMetadata } from '../../../../gatsby-config';

import Layout from '../Layout';

import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import AddIcon from '@mui/icons-material/Add';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';

import Moment from 'react-moment';
import {
    Tooltip,
    IconButton,
    MenuItem,
    Table,
    TableHead,
    TableBody,
    TableCell,
    Select,
    TableRow,
    TextField,
    Button,
    Checkbox,
    Divider
} from '@mui/material';
import DataService from '../../../services/dataService';

import { useSelector, useDispatch } from 'react-redux';
import { setEditableForm, setUpdatingData } from '../../../redux/admin/adminSlice';

import CloseIcon from '@mui/icons-material/Close';


const EditFormField = ({ field, fieldIndex, handleSetUpdateButtonDisabled, handleUpdateFormFields, handleDeleteRow }) => {

    const editableForm = useSelector( state => state.admin.editableForm )
    const dispatch = useDispatch();

    const [isRequired, setIsRequired] = React.useState(field.isRequired == 'on');

    const fieldTypes = [
        'text',
        'textarea',
        'select',
        'checkbox'
    ];

    const updateFormField = (key, value) => {
        let newField = field;
        newField[key] = value;

        let newFields = JSON.parse(editableForm.formfields);
        newFields[fieldIndex] = newField;

        let newForm = { ...editableForm };
        newForm.formfields = JSON.stringify(newFields);
        dispatch(setEditableForm({ editableForm: newForm }));

        handleSetUpdateButtonDisabled(false);
    }

    React.useEffect(() => {
        console.log(field.options)
    }, [])

    return (
        <TableRow 
            style={{
                justifyContent: 'space-around',
                backgroundColor: (fieldIndex % 2 == 0) ? 'whitesmoke' : 'white'
            }}
        >
            <TableCell style={{ textAlign: 'center' }}>
                <TextField 
                    value={field.name} 
                    onChange={(e) => {
                        // setFieldName(e.target.value); 
                        // setStateChanged(!stateChanged)
                        // updateFormFields();
                        updateFormField('name', e.target.value);
                    }}
                />
            </TableCell>
            <TableCell style={{ textAlign: 'center' }}>
                <Select 
                    value={field.type}
                    style={{ width: '100%' }}
                    onChange={(e) => {
                        updateFormField('type', e.target.value)
                    }}
                >
                    {fieldTypes.map( type => (
                        <MenuItem value={type}>
                            {type}

                        </MenuItem>
                    ))}
                </Select>
            </TableCell>
            <TableCell style={{ textAlign: 'center' }}>
                {(() => {
                    switch(field.type) {
                        case 'select':
                            return (
                                <TextField 
                                    onChange={(e) => {
                                        // setFieldOptions(e.target.value);
                                        updateFormField('options', e.target.value)
                                    }}
                                    value={field.options}
                                />
                            )
                        default:
                            return (
                                <p>N/A</p>
                            )
                    }
                })()}
            </TableCell>
            <TableCell style={{ textAlign: 'center' }}>
                {(() => {
                    switch(field.type) {
                        case 'select':
                            return (
                                <Select 
                                    value={field.defaultValue}
                                    onChange={(e) => {
                                        updateFormField('defaultValue', e.target.value);
                                    }}
                                >
                                    <MenuItem value={null} placeholder={"None"}>None</MenuItem>
                                    {/* {JSON.stringify(field)} */}
                                    {field.options[0].split(',').map( option => (
                                        <MenuItem value={option}>
                                            {option}
                                        </MenuItem>
                                    ))}
                                </Select>
                            );

                        case 'checkbox': 
                            return (
                                <Checkbox 
                                    checked={field.defaultFieldValue}
                                />
                            );

                        default:
                            return (
                                <p>N/A</p>
                            );
                    }
                })()}
            </TableCell>
            <TableCell className="text-center">
                <Checkbox 
                    checked={field.required}
                    onChange={(e) => {
                        setIsRequired(e.target.checked);
                        updateFormField('required', e.target.checked);
                    }}
                    className="w-full"
                />
            </TableCell>
            <TableCell>
                <Tooltip 
                    title="Remove" 
                    placement='right'
                    className="hi"
                >
                    <IconButton onClick={() => {

                        const confirm = window.confirm("Are you sure you want to delete this field");

                        if (confirm) {
                            handleDeleteRow(fieldIndex);
                        }
                    }}>
                        <DeleteForeverOutlinedIcon />
                    </IconButton>
                </Tooltip>
            </TableCell>
        </TableRow>
    )
}

const EditForm = ({ data, form, formIndex, handleSetForms, handleRemoveFormFromState }) => {

    const editableForm = useSelector( state => state.admin.editableForm );
    const dispatch = useDispatch();

    const [formFields, setFormFields] = React.useState([]);

    const [updateButtonDisabled, setUpdateButtonDisabled] = React.useState(true);

    const [selectedTemplate, setSelectedTemplate] = React.useState(null);

    React.useEffect(() => {

        console.log(form.template)

        setSelectedTemplate(form.template)
    }, []);

    const handleSetUpdateButtonDisabled = (isDisabled) => {
        setUpdateButtonDisabled(isDisabled);
    }

    const updateFormFields = () => {

        dispatch(setUpdatingData({ updatingData: true }));

        DataService.updateRecord(form.id, {
            formfields: editableForm.formfields
        }).then( updatedForm => {
            dispatch(setEditableForm({ editableForm: updatedForm }))
            dispatch(setUpdatingData({ updatingData: false }));
            handleSetForms(formIndex, updatedForm);
        })
    }

    const updateTemplate = (templateData) => {

        console.log(templateData);

        DataService.updateRecord(form.id, {
            template: templateData.id.split('-')[1]
        }).then( updatedForm => {
            updatedForm.id = `DatoCmsForm-${updatedForm.id}-en`;
            updatedForm.template = { id: `DatoCmsEmailtemplate-${updatedForm.template}-en` }
            handleSetForms(formIndex, updatedForm);
        })
    }
    // "DatoCmsEmailtemplate-122756766-en"
    //  DatoCmsEmailtemplate-122756766-en
    const handleUpdateFormFields = (fieldObject, fieldIndex) => {

        let newFields = formFields;
        newFields[fieldIndex] = fieldObject;

        let newForm = { ...editableForm };
        newForm.formfields = JSON.stringify(newFields);
        dispatch(setEditableForm({ editableForm: newForm }));

        setUpdateButtonDisabled(false);
    }

    const addField = () => {

        let newFields = JSON.parse(editableForm.formfields);
        let newField = {name: '', type: '', options: "", defaultValue: ""};
        newFields.push(newField);

        let newForm = { ...editableForm };
        newForm.formfields = JSON.stringify(newFields);
        dispatch(setEditableForm({ editableForm: newForm }))
    }

    const handleDeleteRow = (fieldIndex) => {

        let newFields = JSON.parse(editableForm.formfields);
        newFields.splice(fieldIndex, 1);

        let newForm = { ...editableForm };
        newForm.formfields = JSON.stringify(newFields);
        dispatch(setEditableForm({ editableForm: newForm }))

        setUpdateButtonDisabled(false);
    }

    return (
        <div className="shadow-md" style={{
            backgroundColor: 'white',
            padding: '2rem',
            width: '90%',
            margin: 'auto',
            position: 'sticky',
            overflow: 'scroll',
            top: 20,
            left: 0,
            right: 0
        }}>
            <div className="mb-5" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h2 className="text-2xl self-center font-semibold w-full text-center pl-8" style={{ fontFamily: 'monospace', color: '#45425a' }}>{editableForm?.title}</h2>
                <div className="flex items-center">
                    <Select
                        value={selectedTemplate ? selectedTemplate.id : "Select Template"}
                        onChange={(e) => {
                            setSelectedTemplate(data.allDatoCmsEmailtemplate.nodes.filter( template => template.id == e.target.value)[0])
                            updateTemplate(data.allDatoCmsEmailtemplate.nodes.filter( template => template.id == e.target.value)[0]);
                        }}
                    >
                        <MenuItem key="first" value={"Select Template"}>
                            Select Template
                        </MenuItem>
                        {data.allDatoCmsEmailtemplate.nodes.map( (template, index) => (
                            <MenuItem key={index} value={template.id}>
                                {template.title}
                            </MenuItem>
                        ))}
                    </Select>
                    <Tooltip 
                        title={"Add Field"} 
                        placement='left'
                        className="hi"
                        style={{
                            top: 0
                        }}
                    >
                        <IconButton onClick={() => {
                            addField();
                        }}
                            style={{
                                height: 50,
                                width: 50,
                                // transform: 'tranlsate(0, -50%)',
                                border: '1px solid #45425a',
                                marginLeft: '1rem'
                            }}
                        >
                            <AddIcon style={{
                                color: '#45425a'
                            }} />
                        </IconButton>
                    </Tooltip>
                </div>
            </div>
            <Table>
                <TableHead
                    style={{
                        
                    }}
                >
                    <TableRow 
                        style={{ 
                            disply: 'flex', 
                            justifyContent: 'space-around',
                            backgroundColor: '#45425a'
                        }}
                    >
                        <TableCell 
                            style={{ 
                                textAlign: 'center', 
                                color: 'white',
                                borderTopLeftRadius: 4
                            }}
                        >
                            Field Name
                        </TableCell>
                        <TableCell 
                            style={{ 
                                textAlign: 'center', 
                                color: 'white',
                            }}
                        >
                            Field Value
                        </TableCell>
                        <TableCell
                            style={{ 
                                textAlign: 'center', 
                                color: 'white',
                            }}
                        >
                            Options (comma separated values)
                        </TableCell>
                        <TableCell
                            style={{ 
                                textAlign: 'center', 
                                color: 'white',
                            }}
                        >
                            Default Value
                        </TableCell>
                        <TableCell
                            style={{
                                textAlign: 'center',
                                color: 'white'
                            }}
                        >
                            Required
                        </TableCell>
                        <TableCell
                            style={{ 
                                textAlign: 'center', 
                                color: 'white',
                                borderTopRightRadius: 4 
                            }}
                        >
                            Delete
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {JSON.parse(editableForm.formfields).map( (field, fieldIndex) => (
                        <EditFormField 
                            field={field}
                            fieldIndex={fieldIndex}
                            handleUpdateFormFields={handleUpdateFormFields}
                            handleDeleteRow={handleDeleteRow}
                            handleSetUpdateButtonDisabled={handleSetUpdateButtonDisabled}
                        />
                    ))}
                </TableBody>
                <div style={{ display: 'flex' }}>
                    <Button 
                        disabled={updateButtonDisabled}
                        style={{
                            backgroundColor: '#45425a',
                            color: 'whitesmoke',
                            opacity: updateButtonDisabled ? 0.2 : 1,
                            marginTop: '1rem'
                        }}
                        onClick={updateFormFields}
                    >
                        Update
                    </Button>
                    <Button 
                        style={{
                            backgroundColor: '#45425a',
                            color: 'whitesmoke',
                            opacity: updateButtonDisabled ? 0.2 : 1,
                            marginTop: '1rem',
                            backgroundColor: 'darkred',
                            marginLeft: '1rem',
                            width: 'max-content'
                        }}
                        onClick={() => {

                            const confirm = window.confirm("Are you sure you want to delete this form?")
                        
                            if (confirm) {
                                
                                DataService.deleteRecord(editableForm.id)
                                    .then( _ => {
                                        
                                        handleRemoveFormFromState(formIndex);
                                    })
                            }
                        }}
                    >
                        Remove Form
                    </Button>
                </div>
            </Table>
        </div>
    )
}

const Forms = ({ data, pageContext, location }) => {

    const editableForm = useSelector( state => state.admin.editableForm );
    const dispatch = useDispatch();


    const [forms, setForms] = React.useState(data.allDatoCmsForm.nodes);

    // const [editableForm, setEditableForm] = React.useState(null);

    const [formIndex, setFormIndex] = React.useState(null);

    const [showEditForm, setShowEditForm] = React.useState(false);

    React.useEffect(() => {

        if (Object.keys(editableForm).length > 0) {
            setShowEditForm(true);
        } else {
            setShowEditForm(false);
        }
    }, [editableForm]);

    React.useEffect(() => {

        if (location.state) {
            if ('reload' in location.state) {

                DataService.getAllRecords(siteMetadata.datoCmsModelIds.Form, data.datoCmsWebsite.id.split('-')[1])
                    .then( forms => {
                        setForms(forms);
                    }
                )
            }
        }
    }, []);

    const handleRemoveFormFromState = (formIndex) => {

        let newForms = forms;
        newForms.splice(formIndex, 1);
        setForms([...newForms]);
        setShowEditForm(false);
    }

    const handleSetForms = (formIndex, updatedForm) => {

        let newForms = forms;
        newForms[formIndex] = updatedForm;
        setForms(newForms);
    }

    return (
        <Layout>
            <div>
                <h1 className="text-2xl font-bold text-center" style={{ fontFamily: 'monospace', color: '#45425a' }}>Forms</h1>

                <Divider />

                <div className="m-5">
                    <div className="grid grid-cols-1 lg:grid-cols-3">
                        {
                            forms.map( (form, formIndex) => (
                                <div 
                                    key={formIndex}
                                    className="m-2 shadow-md"
                                    style={{ 
                                        display: 'flex',
                                        flex: 1, 
                                        position: 'relative', 
                                        justifyContent: 'space-between',
                                        backgroundColor: 'white',
                                        borderStyle: 'solid',
                                        borderColor: 'gray',
                                        borderWidth: 1,
                                        borderRadius: 4,
                                    }}
                                >
                                    <h4 className='text-xl font-semibold m-4 mt-6 mb-6 text-center' style={{ color: '#45425a' }}>{form.title}</h4>

                                    <Tooltip 
                                        title={"Edit"} 
                                        placement='left'
                                        className=""
                                        style={{
                                            width: 50,
                                            height: 50,
                                            position: 'absolute',
                                            top: '50%',
                                            right: '1rem',
                                            transform: 'translate(0, -50%)'
                                        }}
                                    >
                                        <IconButton onClick={() => {
                                            dispatch(setEditableForm({ editableForm: form }));
                                            // console.log(editableForm)
                                            setFormIndex(formIndex);
                                            // doubleToggleShowEditForm();
                                        }}>
                                            <EditOutlinedIcon
                                                style={{ 
                                                    fontSize: 20,
                                                }} 
                                            />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            ))
                        }
                    </div>
                    <div style={{ marginLeft: '2rem', width: '80%' }}>
                        {
                            showEditForm && (
                                <div className="absolute top-0 left-0 w-screen h-screen" style={{ backgroundColor: 'rgba(0, 0, 0, 0.6)' }}>
                                    <Tooltip
                                        title="close"
                                        placement="bottom-start"
                                        // className="right-8 top-8 absolute"
                                        style={{
                                            position: 'absolute',
                                            right: '1rem',
                                            top: '1rem'
                                        }}
                                    >
                                        <IconButton
                                            onClick={() => {
                                                setShowEditForm(false);
                                                dispatch(setEditableForm({ editableForm: {} }));
                                            }}
                                        >
                                            <CloseIcon style={{ color: 'whitesmoke' }} />
                                        </IconButton>
                                    </Tooltip>
                                    <EditForm 
                                        data={data}
                                        form={editableForm}
                                        formIndex={formIndex}
                                        handleRemoveFormFromState={handleRemoveFormFromState}
                                        handleSetForms={handleSetForms}
                                    />
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
            <Tooltip 
                title={"Add"} 
                placement='left-start'
                className="hi"
                style={{
                    position: 'fixed',
                    bottom: 20,
                    right: 20,
                    backgroundColor: '#45425a',
                    width: 100,
                    height: 100,
                    WebkitBoxShadow: '1px 2px 14px 1px rgba(0,0,0,0.80)',
                    boxShadow: '1px 2px 14px 1px rgba(0,0,0,0.80)' 
                }}
            >
                <IconButton 
                    onClick={() => navigate('/admin/forms/create', {
                        state: {
                            siteId: data.datoCmsWebsite.id
                        }
                    })}
                >
                    <AddIcon style={{ textAlign: 'right', fontSize: 50, color: 'white' }} />
                </IconButton>
            </Tooltip>
        </Layout>
    )
}


export const query = graphql`
    query FormQuery($sitename: String) {
        allDatoCmsForm(filter: {site: {name: {eq: $sitename}}}) {
            nodes {
                id
                title
                formfields
                created
                template {
                    id
                    title
                }
            }
        }

        allDatoCmsEmailtemplate(filter: {website: {name: {eq: $sitename}}}) {
            nodes {
                id
                title
            }
        }

        datoCmsWebsite(name: {eq: $sitename}) {
            id
        }
    }
`;

export default Forms;
